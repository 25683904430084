<template>
  <v-app>
    <v-app-bar :title="name" color="primary">
      <template v-slot:prepend>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <template v-slot:append>
        <v-btn icon @click="upload()">
          <v-icon icon="mdi-upload" />
        </v-btn>
        <v-btn icon @click="download()">
          <v-icon icon="mdi-download" />
        </v-btn>
        <v-btn icon @click="save()">
          <v-icon icon="mdi-content-save" />
        </v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item to="/">Offer Management</v-list-item>
        <v-list-item to="/sku-management">SKU Management</v-list-item>
        <v-list-item to="/ttop-generation">TTOP Generation</v-list-item>
        <!-- <v-list-item to="/axis-table">Axis Table</v-list-item> -->
        <v-list-item to="/csv-compare">CSV Compare</v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: 100vh">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import useStore from './js/store'

export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    const route = useRoute()

    const { save, download, upload } = useStore();

    // const router = useRouter()

    // Accéder aux paramètres de la route
    const name = route.name

    // Naviguer vers une autre route
    // router.push({ name: 'Home' })

    const drawer = ref(true);

    return {
      drawer,
      name,
      save,
      download,
      upload
    }
  }
})
</script>

<style></style>
